package support

import techla.base.Key

val deployment = Deployment(
    applicationKey = "BDA06581-BFC1-4655-803D-36C255C4191E",
    applicationSecret = "A7E1FA86-90E3-4CD9-BBB0-8211F256AE0F",
    primary = Key("mos"),
    redirect = "",
    isSandbox = true,
    version = "2.1.10-SNAPSHOT",
    build = 45,
    workspaceName = { crew -> "moodi-${crew.lowercase()}-sandbox"}
)